































































































import Vue from "vue";
import Header from "@/components/layout/SearchHeader.vue";
import { getLocale } from "@/i18n";
export default Vue.extend({
  components: { Header },
  metaInfo: {
    title: "Impressum - Einander Helfen",
    link: [{ rel: "canonical", href: "https://einander-helfen.org/imprint" }],
  },
  data(): {
    items: any;
  } {
    return {
      items: [
        {
          position: "Logo von einander-helfen.org",
          author: "IIT Bombay",
          source:
            "https://commons.wikimedia.org/wiki/File:Heart-hand-shake.svg",
          license:
            "The copyright holder of this file, IIT Bombay, allows anyone to use it for any purpose, provided that the copyright holder is properly attributed. Redistribution, derivative work, commercial use, and all other use is permitted.",
        },
        {
          position:
            'Im Dropdownmenü links neben "Deutschland" (Deutschlandflagge)',
          author: "SKopp, Madden und andere User",
          source: "https://commons.wikimedia.org/wiki/File:Flag_of_Germany.svg",
          license:
            "This image of simple geometry is ineligible for copyright and therefore in the public domain, because it consists entirely of information that is common property and contains no original authorship.",
        },
        {
          position: 'Im Dropdownmenü links neben "International" (Weltkugel)',
          author: "Tommaso.sansone91",
          source: "https://commons.wikimedia.org/wiki/File:Earth_icon_2.png",
          license:
            "This file is made available under the Creative Commons CC0 1.0 Universal Public Domain Dedication. The person who associated a work with this deed has dedicated the work to the public domain by waiving all of their rights to the work worldwide under copyright law, including all related and neighboring rights, to the extent allowed by law. You can copy, modify, distribute and perform the work, even for commercial purposes, all without asking permission.",
        },
        {
          position: "Auf der Startseite erstes Bild im Karussel",
          author: "truthseeker08",
          source:
            "https://pixabay.com/de/photos/hospiz-pflege-krankenpflege-1797305/",
          license: "https://pixabay.com/de/service/terms/#license",
        },
        {
          position: "Auf der Startseite zweites Bild im Karussel",
          author: "timkraaijvanger",
          source:
            "https://pixabay.com/de/photos/kind-kleinkind-süß-blondine-blume-3089906/",
          license: "https://pixabay.com/de/service/terms/#license",
        },
        {
          position: "Auf der Startseite drittes Bild im Karussel",
          author: "Anemone123",
          source:
            "https://pixabay.com/de/photos/teamgeist-zusammenhalt-gemeinsam-2447163/",
          license: "https://pixabay.com/de/service/terms/#license",
        },
        {
          position: "Auf der Startseite unten das Bild auf der ersten Kachel",
          author: "cottonbro",
          source:
            "https://www.pexels.com/de-de/foto/lebensmittel-hande-liebe-menschen-3992383/",
          license: "https://www.pexels.com/de-de/lizenz/",
        },
        {
          position: "Auf der Startseite unten das Bild auf der zweiten Kachel",
          author: "Andrea Piacquadio",
          source:
            "https://www.pexels.com/de-de/foto/mann-menschen-frau-schreibtisch-3769981/",
          license: "https://www.pexels.com/de-de/lizenz/",
        },
        {
          position: "Auf der Startseite unten das Bild auf der vierten Kachel",
          author: "fauxels",
          source:
            "https://www.pexels.com/de-de/foto/mann-menschen-frau-schreibtisch-3184357/",
          license: "https://www.pexels.com/de-de/lizenz/",
        },
        {
          position: "Auf der Startseite unten das Bild auf der dritten Kachel",
          author: "Andrea Piacquadio",
          source:
            "https://www.pexels.com/de-de/foto/lebensmittel-mann-menschen-frau-3772534/",
          license: "https://www.pexels.com/de-de/lizenz/",
        },
        {
          position: "Icon auf der PageNotFound Seite",
          author: "https://www.freepik.com",
          source: "https://www.flaticon.com/free-icon/robot_3398613",
          license: "https://www.freepikcompany.com/legal",
        },
        {
          position: 'Im Dropdownmenü links neben "Englisch" (USA Flagge)',
          author: "Dbenbenn, Zscout370, Jacobolus, Indolences, Technion.",
          source:
            "https://commons.wikimedia.org/wiki/File:Flag_of_the_United_States.svg",
          license:
            "This image is ineligible for copyright and therefore is in the public domain, because it consists entirely of information that is common property and contains no original authorship.",
        },
      ],
    };
  },
  methods: {
    getLocale: getLocale,
  },
});
